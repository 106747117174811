import React from "react";
import logo from "../../images/desihisab-logo.webp";
import styles from "./DesiHisabLogo.module.css";

function DesiHisabLogo() {
  return (
    <div className={styles.desi}>
      <img src={logo} alt="Desi Hisab"/>
    </div>
  );
}

export default DesiHisabLogo;



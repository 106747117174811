import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import styles from "../../components/Footer/FooterContactUsPopup.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { ContactUsApi } from "../api";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import ReplayIcon from "@mui/icons-material/Replay";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D3D3D3",
      },
      "&:hover fieldset": {
        borderColor: "#D3D3D3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D3D3D3",
      },
    },
    "& .MuiInputLabel-root": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "&.filled": {
      backgroundColor: "white",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

const ContactUsForm = ({ open, onClose }) => {
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const [formdata, setFormdata] = useState({
    name: "",
    mobile_number: "",
    category: "",
    subject: "",
    message: "",
    user_from: "Website",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobile_number: "",
    category: "",
    subject: "",
    message: "",
    user_from: "Website",
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const formFields = [
        "name",
        "mobile_number",
        "category",
        "subject",
        "message",
      ];
      const activeField = formFields.indexOf(event.target.name);
      if (activeField !== -1 && activeField < formFields.length - 1) {
        const nextField = formFields[activeField + 1];
        document.getElementById(nextField).focus();
      } else if (activeField === formFields.length - 1) {
        document.getElementById("user_captcha_input").focus();
      } else if (event.target.name === "category") {
        const selectField = document.getElementById("category");
        selectField.dispatchEvent(new Event("change", { bubbles: true }));
      } else if (event.target.name === "user_captcha_input") {
        handleSendClick(event);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validInput = /^[A-Za-z' ]+$/;
    if (name === "mobile_number") {
      const validPhoneNumber = /^\d{1,10}$/;
      if (validPhoneNumber.test(value) || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setFormdata((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setSuccessMessage("");
      }
    } else if (validInput.test(value) || value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
      setFormdata((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setSuccessMessage("");
    }
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      category: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      category: "",
    }));
  };

  const validateCaptchaField = (captchaValue) => {
    if (!captchaValue) {
      return "Please fill in the captcha.";
    } else if (!validateCaptcha(captchaValue)) {
      document.getElementById("user_captcha_input").value = "";
      return "Captcha does not match.";
    }
    return "";
  };

  const handleSendClick = async (event) => {
    if (!navigator.onLine) {
        setLoader(false);
        setNetworkError(
          "You are currently offline. Please check your internet connection."
        );
      return;
    }
    setLoader(true);
    setNetworkError("")
    event.preventDefault();
    const { isValid, errors } = validateForm();
    setErrors(errors);
    const captchaValue = document.getElementById("user_captcha_input").value;
    const captchaError = validateCaptchaField(captchaValue);
    setCaptchaError(!!captchaError);

    if (!isValid || captchaError) {
      setLoader(false);
      return;
    }

    if (validateCaptcha(captchaValue) === true) {
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
    } else {
      setCaptchaError(true);
      validateForm();
      document.getElementById("user_captcha_input").value = "";
      return;
    }
    const formData = new FormData();
    formData.append("name", formdata.name);
    formData.append("mobile_number", formdata.mobile_number);
    formData.append("category", formdata.category);
    formData.append("subject", formdata.subject);
    formData.append("message", formdata.message);
    formData.append("user_from", formdata.user_from);

    try {
      const response = await ContactUsApi(formData);
      if (response.data.status === "200") {
        setLoader(false);
        setSuccessMessage(response.data.message);
        setFormdata({
          name: "",
          mobile_number: "",
          category: "",
          subject: "",
          message: "",
          user_from: "Website",
        });
        setErrorMessage(response.data.status);
      } else {
        setErrorMessage(response.data.status);
        setSuccessMessage(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error", error);
      setLoader(false);
    }
  };
  const validateForm = () => {
    setSuccessMessage("");
    const validPhoneNumber = /^[6-9]\d{9}$/;
    const errors = {
      name: !formdata.name && "Please enter your name.",
      mobile_number:
        (!formdata.mobile_number && "Please enter your phone number.") ||
        (!validPhoneNumber.test(formdata.mobile_number) &&
          "Please enter a valid phone number"),
      category: !formdata.category && "Please select a category.",
      subject: !formdata.subject && "Please enter your subject.",
      message: !formdata.message && "Please enter your message.",
    };
    const isValid = Object.values(errors).every((error) => !error);
    return { isValid, errors };
  };

  const handleErrorMessage = (field) => {
    return (
      errors[field] && (
        <div className={styles.errorMessage}>{errors[field]}</div>
      )
    );
  };

  const handleReloadCaptcha = () => {
    loadCaptchaEnginge(6);
  };

  useEffect(() => {
    const initializeCaptcha = async () => {
      await new Promise((resolve) => {
        loadCaptchaEnginge(6);
        document.addEventListener("DOMContentLoaded", resolve);
      });
    };
    setTimeout(() => {
      initializeCaptcha();
    }, 0);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className={styles.closeicon}>
        <DialogTitle>Contact Us</DialogTitle>
        <CloseIcon className={styles.justifycloseicon} onClick={onClose} />
      </div>
      <Box className={styles.maindialogcontent}>
        <DialogContent className={styles.dialogContent}>
          <div className={styles.flexContainer}>
            <div className={styles.formContainer}>
              <Box className={styles.forminputmain}>
                <Box className={styles.contactfiels}>
                  <Box className={styles.namefiels}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Name"
                      type="text"
                      name="name"
                      fullWidth
                      value={formdata.name}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={classes.root}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.root,
                        },
                      }}
                    />
                    {handleErrorMessage("name")}
                  </Box>

                  <Box className={styles.mobilefiels}>
                    <TextField
                      margin="dense"
                      id="mobile_number"
                      label="Mobile Number"
                      type="text"
                      fullWidth
                      name="mobile_number"
                      value={formdata.mobile_number}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={classes.root}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.root,
                        },
                        inputProps: { maxLength: 10 },
                      }}
                    />
                    {handleErrorMessage("mobile_number")}
                  </Box>
                </Box>

                <TextField
                  margin="dense"
                  id="category"
                  label="Select Category"
                  type="text"
                  fullWidth
                  select
                  name="category"
                  value={formdata.category}
                  onChange={handleCategoryChange}
                  onKeyDown={handleKeyDown}
                  className={classes.root}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.root,
                    },
                  }}
                >
                  <MenuItem value="Issue">Issue</MenuItem>
                  <MenuItem value="Suggestion">Suggestion</MenuItem>
                </TextField>
                {handleErrorMessage("category")}

                <TextField
                  margin="dense"
                  id="subject"
                  label="Subject"
                  type="text"
                  fullWidth
                  name="subject"
                  value={formdata.subject}
                  onChange={handleChange}
                  className={classes.root}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.root,
                    },
                  }}
                />
                {handleErrorMessage("subject")}

                <TextField
                  margin="dense"
                  id="message"
                  label="Message"
                  multiline
                  rows={4}
                  fullWidth
                  name="message"
                  value={formdata.message}
                  onChange={handleChange}
                  className={classes.root}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.root,
                    },
                  }}
                />
                {handleErrorMessage("message")}

                <Box className={styles.textFieldCaptcha}>
                  <Box className={styles.captchaBox}>
                    <Box className={styles.loadCanvas}>
                      <LoadCanvasTemplateNoReload
                        className={styles.loadCaptcha}
                      />
                      <Box className={styles.reloadCaptcha}>
                        <ReplayIcon onClick={handleReloadCaptcha} />
                      </Box>
                    </Box>

                    <Box>
                      <TextField
                        margin="dense"
                        id="user_captcha_input"
                        name="user_captcha_input"
                        label="Enter Captcha"
                        onKeyDown={handleKeyDown}
                        className={`${classes.root} ${
                          isMobile ? classes.fullWidth : ""
                        }`}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.root,
                          },
                        }}
                      />
                      <Box>
                        {captchaError && (
                          <span className={styles.captchaErrorMessage}>
                            Captcha Does Not Match
                          </span>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {successMessage && (
                  <p
                    style={{ color: errorMessage === "200" ? "green" : "red" }}
                    className={styles.getsuccessMessage}
                  >
                    {successMessage}
                  </p>
                )}
              </Box>
              <Box className={styles.contactnetworkErrorMessage}>{networkError}</Box>
              <Box className={styles.btnborder}></Box>
              <DialogActions className={styles.btnjustify}>
                <Button className={styles.btnsend} onClick={handleSendClick}>
                  {loader === false ? (
                    "Submit"
                  ) : (
                    <Box className={styles.loaderLogin}>
                      <Stack sx={{ color: "grey" }} spacing={2} direction="row">
                        <CircularProgress color="inherit" size={22} />
                      </Stack>
                    </Box>
                  )}
                </Button>
              </DialogActions>
            </div>
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ContactUsForm;

import React, { useEffect } from "react";
import Analyse from "../../images/analyseIncome-(1).webp";
import styles from "../InsightsReports/InsightReport.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function InsightReportsLogo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={styles.Analyse_img}>
      <img src={Analyse} alt="Desi Hisab" loading="lazy" />
    </div>
  );
}

export default InsightReportsLogo;

import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "../FooterFAQs/FooterFAQs.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FooterFAQsHeader = ({jsonData}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Grid item xs={12} className={styles.faqsHeader}>
      <Box className={styles.faqsMain}>
        {
          jsonData?.faqs?.map((faq, index) => (
            <Box
              key={faq?.id}
              className={
                openIndex === index ? styles.faqsmainbox : styles.faqsText
              }
            >
              <Box
                className={styles.mainfaqstitle}
                onClick={() => handleToggle(index)}
              >
                <Box>
                  <Typography className={styles.privacyPolicyHead}>
                    {faq?.question}
                  </Typography>
                </Box>
                <Box>
                  {openIndex === index ? (
                    <RemoveIcon className={styles.privacyPolicyHead} />
                  ) : (
                    <AddIcon className={styles.privacyPolicyHead} />
                  )}
                </Box>
              </Box>
              {openIndex === index && (
                <Typography
                  className={styles.faqsInformation}
                  dangerouslySetInnerHTML={{ __html: faq?.answer }}
                />
              )}
            </Box>
          ))
        }
      </Box>
    </Grid>
  );
};

export default FooterFAQsHeader;

 import React from 'react'
 import { Box, Grid, Typography } from "@mui/material";
 import styles from "../FooterFeatures/Features.module.css"

  const FooterFeaturesHeader = () => {
   return (
      <Grid item xs={12} className={styles.featuresHeader}>
            <Box className={styles.featuresText}>
                <Box>
                    <Typography className={styles.featuresHeaderText}>
                      1. Create Hisab
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresInformation}>
                        You have to first "Create Hisab" to use the application. Hisab can be created with any purpose like Person Expenses, Outing with Friends, Rent a Property, Group Expense…etc.
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresHeaderText}>
                      2. Add Income
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresInformation}>
                        You can add "Income" as a transaction under any Hisab. Transaction added as an Income will plus the total amount of your Hisab.
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresHeaderText}>
                      3. Add Expense
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresInformation}>
                        You can add "Expense" as a transaction under any Hisab. Transaction added as an Expense will minus the total amount of your Hisab.
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresHeaderText}>
                      4. Keep Track of your Money
                    </Typography>
                    <br/>
                    <Typography className={styles.featuresInformation}>
                       You can keep track of Total, Income, Expense and Due categories with invited members by clicking on "Detail" link.
                    </Typography>
                </Box>
            </Box>
        </Grid>
   )
 }
 export default FooterFeaturesHeader;
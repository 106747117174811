import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import expenses from "../../images/keepTrack.webp";
import styles from "../Expenses/ExpenseSection.module.css";

function ExpensesLogo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={styles.exp_img}>
      <img src={expenses} alt="Expenses" loading="lazy" />
    </div>
  );
}

export default ExpensesLogo;

import { Grid } from "@mui/material";
import SharableExpense from "./SharableExpense";
import styles from "./ShareableExpensSection.module.css";
import ShareableExpensesLogo from "./ShareableExpensesLogo";
import Container from '@mui/material/Container';
function ShareableExpensSection() {
  return (
    <>
    <div className={styles.ShareableExpensSection_mainsection}>
     <Container maxWidth="xl">
       <Grid container className={styles.ShareableExpensSection_mainWrap}>
        <Grid item xs={12} md={6} className={styles.ShareableExpensSection}>
          <SharableExpense />
        </Grid>
        <Grid item xs={12} md={6} className={styles.ShareableExpensSectionTrack}>
          <ShareableExpensesLogo />
        </Grid>
      </Grid>
      </Container>
      </div>
    </>
  )
}

export default ShareableExpensSection

import React from "react";
import styles from "../PrivacyPolicy/Privacypage.module.css";
import { Box, Grid } from "@mui/material";
import logoimage from "../../images/desihisab-logo.webp";
import { useNavigate } from "react-router-dom";

const Navbar = ({ pageName }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <Grid
      container
      style={{ backgroundImage: `url(${pageName?.backimg})` }}
      className={styles.mainNavbar}
    >
      <Grid item xs={12} className={styles.navebarHead}>
        <Box>
          <img
            onClick={handleClick}
            className={styles.logoimage}
            alt="Desi Hisab"
            src={logoimage}
          />
        </Box>
        <Box>
          <h2 className={styles.pivacyTital}>{pageName?.navbarName}</h2>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Navbar;

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Navbar from "../PrivacyPolicy/Navbar";
import FooterFAQsHeader from "./FooterFAQsHeader";
import FooterSection from "../Footer/FooterSection";
import backgroundImage from "../../images/backgroundimg.png";

const FooterFAQs = ({jsonData}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <Box>
      <Navbar
        pageName={{
          navbarName: "FAQS",
          backimg: backgroundImage,
        }}
      />
      <FooterFAQsHeader jsonData={jsonData}/>
      <FooterSection />
    </Box>
  );
};
export default FooterFAQs;

import React, { useEffect } from "react";
import Navbar from "../PrivacyPolicy/Navbar";
import { Box } from "@mui/material";
import FooterFeaturesHeader from "./FooterFeaturesHeader";
import FooterSection from "../Footer/FooterSection";
import backgroundImage from "../../images/backgroundimg.png";

const FooterFeatures = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <Box>
      <Navbar
        pageName={{
          navbarName: "FEATURES",
          backimg: backgroundImage,
        }}
      />
      <FooterFeaturesHeader />
      <FooterSection />
    </Box>
  );
};
export default FooterFeatures;

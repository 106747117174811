import { Grid } from "@mui/material";
import Testimonials from "./Testimonials";
import styles from "./TestimonialsSection.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
function TestimonialSection() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Grid container className={styles.testimonialsmainwrapcontainer}>
      <Testimonials />
    </Grid>
  );
}

export default TestimonialSection;

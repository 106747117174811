import axios from "axios";

export const loginApi = async (data, method = "POST") => {
  const url = process.env.REACT_APP_API_ENDPOINT + "invite";
  try {
    const response = await axios({
      url,
      method,
      data,
    });

    if (response && response) {
      return response;
    } else {
      console.error("API call error: No data in the response");
      throw new Error("No data in the response");
    }
  } catch (error) {
    console.error("API call error:", error.message);
    throw error;
  }
};


export const ContactUsApi = async (data, method = "POST") => {
  const url = process.env.REACT_APP_API_ENDPOINT+"contact_us";
  try {
    const response = await axios({
      url,
      method,
      data,
    });

    if (response && response) {
      return response;
    } else {
      console.error("API call error: No data in the response");
      throw new Error("No data in the response");
    }
  } catch (error) {
    console.error("API call error:", error.message);
    throw error;
  }
};
import styles from './FooterLogo.module.css';
import handmobileimage from '../../images/handFooter.webp';

function FooterLogo() {
  return (
    <div className={styles.footerlogotext}>
      <img src={handmobileimage} alt="Desi Hisab" className={styles.mobileimagefooter} />
    </div>
  )
}
export default FooterLogo;

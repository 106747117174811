import { useState } from 'react';
import styles from './Testimonials.module.css';
import lineImage from '../../images/Line 3.png';
import { carouselContent } from '../Constants.js';
import { Box, Grid, Rating } from '@mui/material';
import manimage from '../../images/Layer07651.webp';
import Container from '@mui/material/Container';
import arrowleft from '../../images/teeny1.webp';
import arrowright from '../../images/teeny.webp';
import SwipeableViews from 'react-swipeable-views';

function Testimonials() {
  const [value, setValue] = useState(5);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(null);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselContent.length);
    setActiveButton('next');
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselContent.length - 1 : prevIndex - 1
    );
    setActiveButton('prev');
  };

  const handleChangeIndex = (index) => {
    setCurrentIndex(index);
  };

  const currentContent = carouselContent[currentIndex];

  return (
    <Container maxWidth="xl" className={styles.testimonialsmainsection}> 
    <Box className={styles.testimonialsmainboxwrap}>

      <Grid container className={styles.ShareableExpensSection_mainWrap}>
        <Grid item xs={12} md={6} className={styles.ShareableExpensSection}>
          <img src={lineImage}  className={styles.lineImage} alt='Desi Hisab' />
          <span className={styles.incomeTitleexpenses}>testimonials</span>
          <span className={styles.saidClients}>What say our clients</span>

          <div style={{ maxWidth: "500px" }}>
          <SwipeableViews
              index={currentIndex}
              onChangeIndex={handleChangeIndex}
              enableMouseEvents
            >
              {carouselContent.map((content, index) => (
                <div key={index} className={styles.slide}>
                  <span className={styles.contenttext}>{content.text}</span>
                </div>
              ))}
            </SwipeableViews>
            
            <Grid container>
              <Grid item xs={6} className={styles.currentContent}>
                <span variant="caption" className={styles.authorname}>{currentContent.author}</span>
                <Box
                  sx={{
                    '& > legend': { mt: 2 },
                  }}
                >
                  <Rating
                    name="simple-controlled"
                    className={styles.ratingcontent}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} className={styles.buttonwrap}>
                <div style={{ marginTop: "20px", display: 'flex' }}>
                <div
                    onClick={handlePrev}
                    className={`${styles.prevButton} ${activeButton === 'prev' ? styles.activeButton : ''}`}
                  >
                    <img src={arrowleft} className={styles.btnprevarrow} style={{colors:"yellow"}} alt="Previous" />
                  </div>
                  <div
                    onClick={handleNext}
                    style={{ marginLeft: '10px' }}
                    className={`${styles.nextButton} ${activeButton === 'next' ? styles.activeButton : ''}`}
                  >
                    <img src={arrowright}  alt="Next" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={6} className={styles.ShareableExpensSectionTrack}>
          <img src={manimage}  alt = "Desi Hisab" className={styles.shareableimage} />
        </Grid>
      </Grid>

    </Box>
    </Container>
  )
}

export default Testimonials

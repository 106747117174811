import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Navbar from "../PrivacyPolicy/Navbar";
import FooterAboutUsHeader from "./FooterAboutUsHeader";
import FooterSection from "../Footer/FooterSection";
import backgroundImage from "../../images/backgroundimg.png";

const FooterAboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <Box>
      <Navbar
        pageName={{
          navbarName: "ABOUT US",
          backimg: backgroundImage,
        }}
      />
      <FooterAboutUsHeader />
      <FooterSection />
    </Box>
  );
};
export default FooterAboutUs;

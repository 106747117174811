import { Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { ExpenseData } from "../Constants.js";
import lineImage from "../../images/Line 3.png";
import styles from "./ExpensesTracking.module.css";

function ExpensesTracking() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className={styles.expense_sec}>
      <img
        src={lineImage}
        className={styles.lineImage}
        alt="data icon for great"
      />
      <span className={styles.incomeTitleexpenses}>Income / expense</span>
      <div className={styles.expensesmaintitlewrap}>
        <span className={styles.title}>
          Keep track of your Expenses, Dues and Debts
        </span>
      </div>
      <>
        {ExpenseData.map((data) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={data.id}
            className={styles.containerwrap}
          >
            <Grid item className={styles.circlewithlogo}>
              <div className={styles.circlewithtext}>
                <img src={data.icon} alt="Desi Hisab" />
              </div>
            </Grid>

            <Grid item xs={12} md={10}>
              <span className={styles.textheading}>{data.heading}</span>
              <span className={styles.textdescription}>{data.description}</span>
            </Grid>
          </Grid>
        ))}
      </>
    </div>
  );
}

export default ExpensesTracking;

import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import styles from "../FooterAbout/AboutUs.module.css"

const FooterAboutUsHeader = () => {
    return (
        <Grid item xs={12} className={styles.aboutUsHeader}>
            <Box className={styles.aboutUsText}>
                <Box>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab is the first FREE real time instant transaction sharing application introduced by SecureMetaSys Infotech Pvt. Ltd. Now you can manage all your Dues, Debts, Expenses and Incomes with your friends, families, colleagues and loved ones.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    You can create a Hisab of your choice, you can add your friends from your phones contact list to the Hisab with a specific permission to either “View Hisab” or “Edit Hisab”. Only the Hisab owner will have the permission to add or remove participants from the Hisab. Also, Hisab owner and the participant with “Edit Permission” can only add the Income / Expense transactions in particular Hisab. Here the most important thing is transaction added under any Hisab will be “Edit” or “Delete” within 5 minutes of its creation time.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    All you need is your working mobile number to use this application.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    Top Reasons to Download this App:
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                     1. Manage Income / Expense real time
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab is designed for all your day to day real time expenses which we generally do with our friends in terms of contributions, help, due and debts. By using this application we can easily keep track of our spending and money with the borrower. This application is also very useful while we are doing a group expense like we are travelling together or doing one to one expense like rent, bill, shopkeeper or a friend. Using this application everyone will be always aware with individual’s payment status.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    2. Share Transactions Instantly
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab allows you to share your Hisab with your contact list. Once you add participant in a particular Hisab he / she can have an instant access on the financial summary.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    3. Limited Access
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab give you the feature to set a “Permission” while adding any participant from your contact list. Participant with “Edit Hisab” will have a permission to Add, Edit and Delete a transaction in a particular Hisab. Here the most important thing is transaction added under any Hisab will “Edit” or “Delete” within 5 minutes of its creation time.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    4. Free
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab is very first FREE real time instant transaction sharing application.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    5. Fast
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    DesiHisab works very well with low internet connectivity without any interruption or delay.
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsHeaderText}>
                    6. Secure
                    </Typography>
                    <br/>
                    <Typography className={styles.aboutUsInformation}>
                    All your data on DesiHisa is “end-to-end” encrypted.
                    </Typography>
                    <br/>
                </Box>
            </Box>
        </Grid>
    )
}
export default FooterAboutUsHeader;
import { useEffect, useState } from "react";
import styles from "./FooterLogin.module.css";
import qrcode from "../../images/clarity_qr-code-line.webp";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import { loginApi } from "../api";

import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import ReplayIcon from "@mui/icons-material/Replay";

function FooterLogin() {
  const [phone, setPhone] = useState("");
  const [errorColor, setErrorColor] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [loader, setLoader] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [numberArray, setNumberArray] = useState([]);
  const [loginAttempts, setLoginAttempts] = useState(0);

  const loginFun = async () => {
    if (!navigator.onLine) {
      if (phone.length !== 10 || !/^[6-9]\d{9}$/.test(phone)) {
        setPhoneError(true);
        setNetworkError("");
      } else {
        setLoader(false);
        setPhoneError(false);
        setNetworkError(
          "You are currently offline. Please check your internet connection."
        );
        // setLoginSuccess(
        //   "You are currently offline. Please check your internet connection."
        // );
      }
      return;
    }
    setLoader(true);
    const phoneNumberPattern = /^[6-9]\d{9}$/;
    if (phone.length === 10 && phoneNumberPattern.test(phone)) {
      const formdata = new FormData();
      formdata.append("mobile_number", phone);
      try {
        if (loginAttempts < 2) {
          const result = await loginApi(formdata);
          if (result.data.status === "200") {
            setNetworkError("");
            setPhone("");
            setLoginSuccess(result.data.message);
            setPhoneError(false);
            setLoader(false);
            setNumberArray((prevArray) => {
              if (
                prevArray.length > 0 &&
                prevArray[prevArray.length - 1] === phone
              ) {
                setLoginAttempts(loginAttempts + 1);
                return [...prevArray, phone];
              } else {
                setLoginAttempts(1);
                return [phone];
              }
            });
            setErrorColor(result.data.status);
          } else {
            setLoginSuccess(result.data.message);
            setErrorColor(result.data.status);
            setPhone("");
            setLoader(false);
          }
        } else {
          if (numberArray[numberArray.length - 1] === phone) {
            setOpenPopup(true);
            setLoader(false);
            setLoginAttempts(0);
          } else {
            setLoginAttempts(loginAttempts + 1);
            setNumberArray((prevArray) => [...prevArray, phone]);
            const result = await loginApi(formdata);
            if (result.data.status === "200") {
              setPhone("");
              setLoginSuccess(result.data.message);
              setPhoneError(false);
              setLoginAttempts(1);
              setErrorColor(result.data.status);
            } else {
              setLoginSuccess(result.data.message);
              setErrorColor(result.data.status);
              setPhone("");
            }
          }
        }
      } catch (error) {
        setLoginSuccess("");
      }
      setPhoneError(false);
    } else {
      setPhoneError(true);
      setLoginSuccess("");
      setLoader(false);
    }
  };

  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setPhone(numericValue);
    setLoginSuccess("");
  };

  const handlePhoneBlur = () => {
    setTimeout(() => {
      if (phone.length !== 10 || !/^[6-9]\d{9}$/.test(phone)) {
        setPhoneError(true);
        setLoginSuccess("");
        setNetworkError("");
      }
    }, 100);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    if (openPopup) {
      const initializeCaptcha = async () => {
        await new Promise((resolve) => {
          loadCaptchaEnginge(6);
          document.addEventListener("DOMContentLoaded", resolve);
        });
      };
      setTimeout(() => {
        initializeCaptcha();
      }, 0);
    }
  }, [openPopup]);

  const captchaSubmit = () => {
    let userCaptcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(userCaptcha) === true) {
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
      loginFun();
      setOpenPopup(false);
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
      document.getElementById("user_captcha_input").value = "";
    }
  };

  const handleReloadCaptcha = () => {
    loadCaptchaEnginge(6);
  };

  const handleDrop = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginFun();
    }
  };

  return (
    <div className={styles.footerloginwrap}>
      <span>
        <img
          src={qrcode}
          className={styles.footerloginimage}
          alt="Desi Hisab"
        />
      </span>
      <span className={styles.footerdesihisabtitle}>
        Get started with Desi Hisaab
      </span>

      <div className={styles.textfieldmainwrap}>
        <TextField
          className={`${styles.textfieldwrap} ${
            isFocused ? styles.focused : ""
          }`}
          size="medium"
          placeholder="Enter your phone number"
          value={phone}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
          onFocus={handleFocus}
          onDrop={handleDrop}
          onKeyDown={handleKeyDown}
          // onInput={(e) => {
          //   e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
          // }}
          InputProps={{
            style: {
              fontSize: 16,
              width: "100%",
            },
            inputProps: { maxLength: 10 },
            pattern: "[6-9][0-9]{9}",
            startAdornment: (
              <InputAdornment position="start" className={styles.inputcompare}>
                <span className={styles.InputAdornment}>+91</span>
              </InputAdornment>
            ),
          }}
          // addonKeyDown={handleKeyDown}
        />
        {phoneError && (
          <Box className={styles.invalidPhoneNumberMessagemobilefooter}>
            Please enter a valid phone number
          </Box>
        )}
        <Box
          style={{ color: errorColor === "200" ? "green" : "red" }}
          className={styles.loginSuccessMessagemobilefooter}
        >
          {loginSuccess}
        </Box>
        <Box className={styles.networkErrorMessagemobilefooter}>
          {networkError}
        </Box>

        <Button className={styles.startedbutton} onClick={loginFun}>
          {loader === false ? (
            "Get Started"
          ) : (
            <Box className={styles.loaderLogin}>
              <Stack sx={{ color: "grey" }} spacing={2} direction="row">
                <CircularProgress color="inherit" size={22} />
              </Stack>
            </Box>
          )}
        </Button>
      </div>

      {phoneError && (
        <Box className={styles.invalidPhoneNumberMessage}>
          Please enter a valid phone number
        </Box>
      )}
      <Box
        style={{ color: errorColor === "200" ? "green" : "red" }}
        className={styles.loginSuccessMessage}
      >
        {loginSuccess}
      </Box>
      <Box className={styles.networkErrorMessage}>{networkError}</Box>
      <Modal
        open={openPopup}
        closeAfterTransition
        className={styles.loginCaptchaModal}
      >
        <Box className={styles.captchaBox}>
          <Box className={styles.loadCanvas}>
            <LoadCanvasTemplateNoReload />
            <ReplayIcon
              className={styles.reloadCaptcha}
              onClick={handleReloadCaptcha}
            />
          </Box>
          {captchaError && (
            <span className={styles.captchaErrorMessage}>
              Captcha Does Not Match
            </span>
          )}
          <Box className={styles.textfieldmaincaptcha}>
            <TextField
              className={styles.captchaTchatextfiel}
              size="medium"
              placeholder="Enter Captcha"
              id="user_captcha_input"
              name="user_captcha_input"
              InputProps={{
                style: {
                  fontSize: 15,
                  // height: 44,
                  width: "100%",
                  // padding: "0",
                },
              }}
            />
            <Button className={styles.startedbutton} onClick={captchaSubmit}>
              Get Started
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FooterLogin;

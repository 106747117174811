import styles from "./ExpenseSection.module.css";
import { Grid } from "@mui/material";
import ExpensesLogo from "./ExpensesLogo";
import ExpensesTracking from "./ExpensesTracking";
import Container from '@mui/material/Container';
function ExpenseSection() {
  return (
    <>
    <Container maxWidth="xl">
       <Grid container className={styles.expenseSection_mainWrap}>
        <Grid item  xs={12} md={6}  sm={12} className={styles.expensesLogo}>
          <ExpensesLogo />
        </Grid>
        <Grid item  xs={12} md={6}  sm={12} className={styles.trackExpensesSection}>
          <ExpensesTracking />
        </Grid>
      </Grid>
    </Container>
    </>
  )
}

export default ExpenseSection

import React from "react";
import { Box } from "@mui/material";
import Navbar from "../PrivacyPolicy/Navbar";
import FooterSecurityHeader from "./FooterSecurityHeader";
import FooterSection from "../Footer/FooterSection";

const FooterSecurity = () => {
  return (
    <Box>
      <Navbar
        pageName={{
          navbarName: "SECURITY",
          backimg: "",
        }}
      />
      <FooterSecurityHeader />
      <FooterSection />
    </Box>
  );
};

export default FooterSecurity;

import { Grid } from "@mui/material";
import FreeFastSecure from "./FreeFastSecure";
import Container from '@mui/material/Container';
import styles from "./FastSecureSection.module.css";
import FastSecureSectionLogo from "./FastSecureSectionLogo";

function FastSecureSection() {
  return (
    <>
     <Container maxWidth="xl">
       <Grid container className={styles.FastSecureSection_mainWrap}>
        <Grid item xs={12} md={6} className={styles.FastSecureSectionLogo}>
          <FastSecureSectionLogo />
        </Grid>
        <Grid item xs={12} md={6} className={styles.FastSecureSection}>
          <FreeFastSecure />
        </Grid>
      </Grid>
      </Container>
    </>
  )
}

export default FastSecureSection

import rupee from "../images/rupee1.webp";
import money from "../images/money1.webp";
import share from "../images/invite1.webp";
import layers from "../images/layers1.webp";
import wallet from "../images/wallet1.webp";
import expense from "../images/income1.webp";
import appstore from "../images/appstore.png";
import desihisab from "../images/income1.webp";
import language from "../images/language1.webp";
import playstore from "../images/googleplay.webp";
import verified from "../images/verified(1)1.webp";
import instagram from "../images/mdi_instagram.png";
import facebook from "../images/ri_facebook-fill.png";
import twitter from "../images/mdi_twitter.png";

// Header Page Json

export const headerTitle = {
  appTitle: {
    className: "freeapptitle",
    title: "#India ka apna Hisab!",
  },
};

export const handalClickgoogleplay = () => {
  window.open("https://play.google.com/store/apps/details?id=com.desihisab", "_blank");
};

export const handalClickAppleStore = () => {
  window.open("https://apps.apple.com/app/desi-hisab/id6471561568", "_blank");
};

export const headerHeading = {
  mainText: {
    className: "maintextwrap",
    text: {
      className: "textwrap",
      content: "Now manage all your Income, Expense, Dues and Debts with your loved instantly in real time ones only on Desi Hisab.",
    },
  },
};

export const buttonDataPlayStore = {
  button: {
    styles: {
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    startIcon: {
      src: playstore,
      className: "button_icon",
    },
    className: "googleplaybtn",
    content: {
      wrapClassName: "btnwrap",
      text: [
        {
          style: {
            fontWeight: "bold",
            textAlign: "left",
          },
          content: "GET IT ON",
          link: "http://plays.google.com",
        },
        {
          content: "Google Play",
        },
      ],
    },
  },
};

export const buttonDataAppleStore = {
  buttons: {
    styles: {
      "&:hover": {
        backgroundColor: "#000",
      },
    },
    startIcon: {
      src: appstore,
      className: "button_icon",
    },
    className: "applebtn",
    content: {
      wrapClassName: "btnwrap",
      text: [
        {
          style: {
            fontWeight: "bold",
          },
          content: "Download on",
        },
        {
          style: {
            textAlign: "left",
          },
          content: "APP Store",
          link: "http://applestore.com",
        },
      ],
    },
  },
};

// Income/Expense Page json data

export const ExpenseData = [
  {
    id: 1,
    icon: desihisab,
    heading: "Create Hisab",
    description:
      "You have to first \"Create Hisab\" to use the application. Hisab can be created with any purpose like Person Expenses, Outing with Friends, Rent a Property, Group Expense…etc. ",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Add Income",
    description:
      "You can add \"Income\" as a transaction under any Hisab. Transaction added as an Income will plus the total amount of your Hisab.",
  },

  {
    id: 3,
    icon: expense,
    heading: "Add Expense",
    description:
      "You can add \"Expense\" as a transaction under any Hisab. Transaction added as an Expense will minus the total amount of your Hisab.",
  },

  {
    id: 4,
    icon: layers,
    heading: "Keep Track of your Money",
    description:
      "You can keep track of Total, Income, Expense and Due categories with invited members by clicking on \"Detail\" link.",
  },
];

// shareable expense page json data

export const shareAbleExpense = [
  {
    id: 1,
    icon: desihisab,
    heading: "Add Participant",
    description:
      "You can your friends and families from your contact list in any Hisab using \"Add Participant\" link in add and edit Hisab page. You can use \"Invite\" option for users who are not using / registered on Desi Hisab application.",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Set Permission",
    description:
      "Desi Hisab allows you set permission either \"Edit Hisab\" or \"View Hisab\" for specific user on particular Hisab. With \"Edit Hisab\" permission participant can add income and expense in your Hisab but with \"View Hisab\" permission he / she can only view the transactions.",
  },
];

// Free Fast Secure page json data

export const freeFastSecure = [
  {
    id: 1,
    icon: rupee,
    heading: "No Subscription",
    description:
      "Desi Hisab application is available on both Android and iOS store in free of cost without any subscription fees or paid feature limit.",
  },
  {
    id: 2,
    icon: verified,
    heading: "End to End Encryption",
    description:
      "With end to end encryption all your Hisabs, Transaction and Profile data are secured. Only you and participant you added in any Hisab will have the access on the transactions added by you and him / her, not even with Desi Hisab.",
  },
  {
    id: 3,
    icon: language,
    heading: "Work with Low Internet",
    description:
      "Desi Hisab is developed by considering the constraint of low internet speed while living or travelling in non-network area / limited internet access to work with same efficiency like high speed internet.",
  },
];

// Testimonial page json data

export const carouselContent = [
  {
    text: "Best application to keep track of your day by day expenses. I also liked the feature of sharing Hisab with friends. I am using it with my friends without any issue.",
    author: "Varun",
    rating: "5 stars",
  },
  {
    text: "I found what I was looking since long time on play store. Very simple app with only focusing on what is your income, what is your expense and what is your due with your friends. ",
    author: "Ravi",
    rating: "5 stars",
  },
  {
    text: "I am using Desi Hisab since last two months and it made my work easy by keeping track of my dues and debts with my friends on my fingertip.",
    author: "Rakesh",
    rating: "5 stars",
  },
];

// Insights reports json page data

export const insightReport = [
  {
    id: 1,
    icon: desihisab,
    heading: "Insights",
    description:
      "Application gives powerful statistics on “Detail” screen on every Hisab. You can easily get the Income, Expense, and Outstanding along with user wise spending details on numeric and graphical format.",
  },
  {
    id: 2,
    icon: wallet,
    heading: "Report",
    description:
      "You can export a report of your Income, Expense and Outstanding with date and time as a PDF format any time and share it with your loved ones on any social media platform.",
  },
];

// invite friends json page data

export const inviteFriends = [
  {
    id: 1,
    icon: share,
    heading: "Invite",
    description:
      "Invite your friend and family members on Desi Hisab using \"Invite\" option during add participant process. Application will send application download link via SMS from your cellular mobile number.",
  },
  {
    id: 2,
    icon: money,
    heading: "Cash Reward",
    description:
      "You can earn as much as you can on Desi Hisab, all you need to do in to invite your real friend and family member from your contact list. Once you’re invited member will registered on this application you will get a “Cash Reward” scratch card. You have to scratch the card and the amount will be credited in your wallet which you can transfer in your account using any UPI method.",
  },
];

// social media links for footer

export const socialMediaLinks = [
  { platform: "Facebook", image: facebook, link: "https://www.facebook.com/desihisaab" },
  { platform: "Twitter", image: twitter, link: "#Twitter" },
  { platform: "Instagram", image: instagram, link: "#Instagram" },
];
